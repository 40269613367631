import Layout from './components/Layout';
import './scss/main.scss'
function App() {
  return (
    <div >
      <Layout />
    </div>
  );
}

export default App;
